.footer {
  background: linear-gradient(135deg, rgba(26, 26, 26, 1), rgba(15, 15, 15, 1));
  padding: 2rem 1rem;
  color: white;
  font-family: "Raleway", sans-serif;
  position: relative;
  overflow: hidden;
}

.footer-content {
  position: relative;
  z-index: 2; 
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2rem;
}

.footer-logo h2 {
  font-family: "Raleway", sans-serif;
  font-size: 1.8rem;
  background: linear-gradient(90deg, #892cdc, #d4a1f2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* Soft neon glow */
    text-shadow: 0 0 8px rgba(137, 44, 220, 0.6), 0 0 16px rgba(137, 44, 220, 0.2);
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1rem;
  animation: fadeInGlow 1.5s ease-in-out forwards;
}

.footer-logo p {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.8);
  margin: 0.5rem 0 0;
  font-weight: 300;
  animation: fadeInGlow 1.8s ease-in-out forwards;
}
.footer-links {
  display: flex;
  gap: 2rem;
  position: relative; 
  z-index: 3; 
}

.icon {
  width: 32px;
  height: 32px;
  fill: white;
  transition: transform 0.3s ease, fill 0.3s ease;
}

.icon:hover {
  fill: #892cdc;
  transform: scale(1.2);
}

.footer-copy p {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.6);
  margin: 0;
  animation: fadeInGlow 2s ease-in-out forwards;
}

.footer::before {
  content: "";
  position: absolute;
  top: -50%;
  left: 0;
  width: 200%;
  height: 200%;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(137, 44, 220, 0.1),
    transparent 70%
  );
  animation: rotateGlow 10s linear infinite;
  transform-origin: center;
  z-index: 1;
}

@keyframes rotateGlow {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fadeInGlow {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@media screen and (max-width: 768px) {
  .footer-logo h2 {
    font-size: 1.5rem;
  }

  .footer-logo p {
    font-size: 0.9rem;
  }

  .icon {
    width: 28px;
    height: 28px;
  }
  .icon:hover {
    transform: none;
    fill: white;
  }
}

@media screen and (max-width: 480px) {
  
  .footer-content {
    gap: 1.5rem;
  }

  .footer-logo h2 {
    font-size: 1.3rem;
  }

  .footer-logo p {
    font-size: 0.8rem;
  }

  .icon {
    width: 24px;
    height: 24px;
  }

  .footer-copy p {
    font-size: 0.8rem;
  }
}
