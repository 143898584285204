.contact-form-box input,
.contact-form-box textarea {
  font-family: inherit;
}

.contact-form-box input:-webkit-autofill,
.contact-form-box textarea:-webkit-autofill {
  background-color: #1a1a1a !important;
  -webkit-text-fill-color: white !important;
  color: white !important;
  box-shadow: inset 0 0 5px rgba(137, 44, 220, 0.2),
    0 0 0px 1000px #1a1a1a inset !important;
  border-radius: 10px;
  transition: background-color 5000s ease-in-out 0s;
  overflow: hidden;
}
.contact-form-box input::placeholder,
.contact-form-box textarea::placeholder {
  font-family: inherit;
  font-size: inherit;
  color: rgba(255, 255, 255, 0.6);
}
.contact-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem 1rem;
  min-height: 100vh;
  color: white;
  margin-top: 0;
  box-sizing: border-box;
  animation: pageFadeIn 1s ease-in-out;
}

.contact-header-box {
  position: relative;
  background: linear-gradient(
    135deg,
    rgba(38, 38, 38, 0.95),
    rgba(20, 20, 20, 0.95)
  );
  border-radius: 20px;
  width: 80%;
  max-width: 600px;
  padding: 2rem;
  box-shadow: 0 0 15px rgba(137, 44, 220, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 7rem;
  z-index: 1;
  box-sizing: border-box;
  animation: fadeInSlide 1s ease-in-out;
}

.contact-header-box h1 {
  font-family: inherit;
  font-weight: 400;
  font-size: 2.8rem;
  background: linear-gradient(90deg, #892cdc, #d4a1f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* Soft neon glow */
  text-shadow: 0 0 8px rgba(137, 44, 220, 0.6), 0 0 16px rgba(137, 44, 220, 0.2);
  margin: 0;
  text-align: center;
}

.avatar-overlay {
  position: absolute;
  top: -7rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.contact-form-box {
  position: relative;
  background: linear-gradient(
    135deg,
    rgba(38, 38, 38, 0.95),
    rgba(20, 20, 20, 0.95)
  );
  border-radius: 20px;
  width: 80%;
  max-width: 600px;
  padding: 2rem;
  box-shadow: 0 0 15px rgba(137, 44, 220, 0.5);
  margin-bottom: 5rem;
  z-index: 3;
  margin-top: 1rem;
  margin-bottom: 2rem;
  box-sizing: border-box;
  animation: fadeInSlide 1s ease-in-out;
  animation-delay: 0.3s;
  animation-fill-mode: both;
}

.contact-form-box form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form-box input,
.contact-form-box textarea {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  background: #1a1a1a;
  color: white;
  box-shadow: inset 0 0 5px rgba(137, 44, 220, 0.2);
  transition: box-shadow 0.3s ease-in-out;
  overflow: hidden;
}

.contact-form-box input:focus,
.contact-form-box textarea:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(137, 44, 220, 0.4);
}
.contact-form-box textarea {
  height: 150px;
  resize: none;
  overflow-y: auto;
}

.contact-form-box button {
  padding: 1rem;
  border: none;
  border-radius: 10px;
  font-size: 1.2rem;
  background: linear-gradient(135deg, #892cdc, #6708b8);
  color: white;
  cursor: pointer;
  box-shadow: 0 0 15px rgba(137, 44, 220, 0.5), 0 0 25px rgba(137, 44, 220, 0.4);
  transition: all 0.4s ease-in-out;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
}
.contact-form-box button::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 0;
  transition: width 0.4s ease, height 0.4s ease, opacity 0.4s ease;
}
.contact-form-box button:active::before {
  width: 200%;
  height: 200%;
  opacity: 0.6;
}

.contact-form-box button:hover {
  background: linear-gradient(135deg, #6708b8, #892cdc);
  box-shadow: 0 0 25px rgba(137, 44, 220, 0.8), 0 0 50px rgba(137, 44, 220, 0.6);
  transform: scale(1.05);
}

.contact-form-box button:active {
  transform: scale(0.95);
  box-shadow: 0 0 10px rgba(137, 44, 220, 0.7), 0 0 25px rgba(137, 44, 220, 0.5);
}

@keyframes dots-animation {
  0% {
    content: "";
  }
  33% {
    content: ".";
  }
  66% {
    content: "..";
  }
  100% {
    content: "...";
  }
}
@keyframes pageFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.loading-message {
  font-size: 1.5rem;
  color: white;
  background: rgba(137, 44, 220, 0.9);
  padding: 1.5rem 2rem;
  border-radius: 20px;
  box-shadow: 0 0 15px rgba(137, 44, 220, 0.6);
  text-align: center;
  max-width: 90%;
  margin: 0;
  word-wrap: break-word;
  position: relative;
  animation: fadeInSlide 0.5s ease-in-out;
}

.loading-dots {
  display: inline-block;
  font-size: 1.5rem;
}

.loading-dots::after {
  content: "...";
  display: inline-block;
  animation: dots-animation 1.5s infinite;
}
.success-overlay,
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: fadeOut 1.5s ease-in-out forwards;
}

.success-message {
  font-size: 1.5rem;
  color: white;
  background: rgba(44, 220, 137, 0.9);
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(44, 220, 137, 0.6);
  text-align: center;
  max-width: 90%;
  word-wrap: break-word;
  z-index: 1000;
  margin: 0;
  position: relative;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}
@keyframes fadeInSlide {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .contact-header-box {
    width: 90%;
  }
  .contact-form-box {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .contact-form-box button:hover {
    background: linear-gradient(135deg, #892cdc, #6708b8);
    box-shadow: none;
    transform: none;
  }

  .contact-header-box {
    padding: 1.5rem;
    width: 95%;
  }

  .contact-header-box h1 {
    font-size: 2.2rem;
  }

  .avatar-overlay {
    top: -5.5rem;
    transform: translateX(-50%) scale(0.9);
  }

  .contact-form-box {
    padding: 1.5rem;
    width: 95%;
  }

  .contact-form-box input,
  .contact-form-box textarea {
    padding: 0.8rem;
    font-size: 0.9rem;
  }

  .contact-form-box textarea {
    height: 120px;
  }

  .contact-form-box button {
    font-size: 1rem;
    padding: 0.8rem;
  }
  .success-message,
  .loading-message {
    font-size: 1.2rem;
    padding: 0.8rem 1.5rem;
  }
}
@media (max-width: 576px) {
  .contact-header-box h1 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .contact-header-box {
    padding: 1rem;
    margin-top: 5rem;
    width: 100%;
  }
  .avatar-overlay {
    top: -4.3rem;
    transform: translateX(-50%) scale(0.8);
  }

  .contact-form-box {
    padding: 1rem;
    width: 100%;
  }

  .contact-form-box input,
  .contact-form-box textarea {
    padding: 0.6rem;
    font-size: 0.8rem;
  }

  .contact-form-box textarea {
    height: 100px;
  }

  .contact-form-box button {
    font-size: 0.9rem;
    padding: 0.6rem;
  }
  .success-message,
  .loading-message {
    font-size: 1rem;
    padding: 0.6rem 1rem;
  }
}

@media (max-width: 387px) {
  .contact-header-box h1 {
    font-size: 1.8rem;
  }
}
@media (max-width: 335px) {
  .contact-header-box h1 {
    font-size: 1.6rem;
  }
  .avatar-overlay {
    top: -3.6rem;
    transform: translateX(-50%) scale(0.75);
  }
}

@media (max-width: 320px) {
  .contact-header-box h1 {
    font-size: 1.5rem;
  }
  .success-message,
  .loading-message {
    font-size: 0.9rem;
    padding: 0.5rem 0.8rem;
  }
}

@media (max-width: 255px) {
  .avatar-overlay {
    top: -3.2rem;
    transform: translateX(-50%) scale(0.7);
  }
}
