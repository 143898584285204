.monitorCanvas-wrapper {
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.monitorCanvas-wrapper > canvas {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  touch-action: none;
}
