html {
  position: relative;
  min-height: 100%;
  background: linear-gradient(135deg, rgba(26, 26, 26, 1), rgba(15, 15, 15, 1));
  font-family: "Raleway", sans-serif;
  color: #ffffff;
  overflow-x: hidden;
}

html::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 50%, rgba(110, 36, 180, 0.2), transparent 70%);
  z-index: -1; 
  opacity: 1; 
  transition: opacity 1s ease; 
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.page-transition {
  animation: slideIn 1s ease-in-out forwards;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button:hover {
  transform: scale(1.1);
  box-shadow: 0 5px 15px rgba(137, 44, 220, 0.2);
}

body {
  transition: background-color 0.5s ease, color 0.5s ease;
}
