* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  padding-top: 70px;
}
.navbar-container {
  font-family: "Raleway", sans-serif;
  position: relative;
  z-index: 9999;
  margin-bottom: 70px;
}

html.no-scroll,
body.no-scroll {
  overflow: hidden !important;
  position: fixed;
  touch-action: none;
  width: 100%;
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  background: linear-gradient(
    135deg,
    rgba(38, 38, 38, 0.95),
    rgba(20, 20, 20, 0.95)
  );
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 9999;
  transition: background 0.4s ease, box-shadow 0.4s ease;
}

.navbar-scrolled {
  background: linear-gradient(
    135deg,
    rgba(26, 26, 26, 0.95),
    rgba(15, 15, 15, 0.95)
  );
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
}

.navbar-brand {
  text-decoration: none;
  font-size: 2.2rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;
  background: linear-gradient(120deg, #d4a1f2, #892cdc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 6px rgba(137, 44, 220, 0.6),
    0px 0px 12px rgba(137, 44, 220, 0.4);
  animation: glowAnimation 3s infinite alternate;
  filter: brightness(1);
  transition: transform 0.3s ease, text-shadow 0.3s ease, filter 0.3s ease;
}

.navbar-brand:hover {
  transform: scale(1.05);

  text-shadow: 0px 0px 10px rgba(137, 44, 220, 0.7),
    0px 0px 15px rgba(137, 44, 220, 0.6);
  filter: brightness(1.2);
}

.brand-symbol {
  margin: 0 4px;
  font-size: 2.4rem;
  background: linear-gradient(120deg, #892cdc, #4a0091);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 6px rgba(137, 44, 220, 0.6),
    0px 0px 12px rgba(137, 44, 220, 0.4);
  animation: glowAnimation 3s infinite alternate;
  transition: transform 0.3s ease, text-shadow 0.3s ease, filter 0.3s ease;
}

.brand-symbol:hover {
  transform: scale(1.08);
  text-shadow: 0px 0px 10px rgba(137, 44, 220, 0.7),
    0px 0px 15px rgba(137, 44, 220, 0.6);
  filter: brightness(1.2);
}

@keyframes glowAnimation {
  0% {
    text-shadow: 0px 0px 4px rgba(137, 44, 220, 0.4),
      0px 0px 8px rgba(137, 44, 220, 0.3);
  }
  100% {
    text-shadow: 0px 0px 10px rgba(137, 44, 220, 0.6),
      0px 0px 15px rgba(137, 44, 220, 0.5);
  }
}

.navbar-menu {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  list-style: none;
  position: relative;
  justify-content: flex-end;
  visibility: visible;
  opacity: 1;
  transform: none;
  transition: none;
}

.navbar-menu.active {
  transform: none;
  visibility: visible;
  opacity: 1;
}

.navbar-item a {
  font-size: 1rem;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  position: relative;
  text-decoration: none;
  transition: color 0.25s ease, text-shadow 0.25s ease;
  padding: 8px 12px;
  will-change: color, text-shadow;
}

.navbar-item a:hover {
  color: #892cdc;
  text-shadow: 0 2px 6px rgba(137, 44, 220, 0.8);
}

.navbar-item .navbar-link.active,
.navbar-item .navbar-link:focus {
  color: #892cdc;
  text-shadow: 0 2px 6px rgba(137, 44, 220, 0.8);
}

.navbar-item a::before,
.navbar-item a::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  width: 0;
  height: 3px;
  background: linear-gradient(90deg, #892cdc, #4a0091);
  border-radius: 50px;
  transition: width 0.3s ease, left 0.3s ease;
  will-change: width, left;
}

.navbar-item a:hover::before,
.navbar-item .navbar-link.active::before,
.navbar-item .navbar-link:focus::before {
  width: 100%;
  left: 0;
}

.navbar-item a::after {
  height: 5px;
  background: rgba(137, 44, 220, 0.2);
  transition: width 0.4s ease, left 0.4s ease;
}

.navbar-item a:hover::after,
.navbar-item .navbar-link.active::after,
.navbar-item .navbar-link:focus::after {
  width: 100%;
  left: 0;
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.navbar-toggle span {
  width: 25px;
  height: 3px;
  background-color: white;
  border-radius: 5px;
  transition: transform 0.2s ease, opacity 0.2s ease;
  will-change: transform, opacity;
}
.navbar-toggle.open span:nth-child(1) {
  transform: rotate(45deg) translate(6px, 6px);
}

.navbar-toggle.open span:nth-child(2) {
  opacity: 0;
}

.navbar-toggle.open span:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -6px);
}
.navbar::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at 50% 50%,
    rgba(137, 44, 220, 0.2),
    transparent 70%
  );
  z-index: -1;
  pointer-events: none;
  opacity: 0.8;
}

@media (max-width: 1024px) {
  .navbar-menu {
    position: fixed;
    top: 70px;
    right: 0;
    flex-direction: column;
    background: linear-gradient(
      135deg,
      rgba(26, 26, 26, 0.98),
      rgba(15, 15, 15, 0.9)
    );
    width: 100%;
    height: calc(100vh - 70px);
    gap: 2rem;
    justify-content: center;
    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;
    backdrop-filter: blur(8px);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5),
      0 0 20px rgba(137, 44, 220, 0.2);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
      opacity 0.4s ease, visibility 0s linear 0.4s;
  }
  .navbar-item a {
    font-size: 1.1rem;
  }
  .navbar-menu.active {
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
      opacity 0.4s ease, visibility 0s linear 0s;
  }
  .navbar-menu.closing {
    transform: translateX(100%);
    opacity: 0;
    visibility: visible;
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1),
      opacity 0.4s ease;
  }

  .navbar-toggle {
    display: flex;
  }
}

@media (max-width: 324px) {
  .navbar-brand {
    font-size: 1.7rem;
  }
  .brand-symbol {
    font-size: 2rem;
  }
}
