.experienceContainer {
  animation: fadeInPage 0.8s ease-in-out;
  text-align: center;
  padding: 4rem 1rem;
  color: white;
  margin-top: 0;
  box-sizing: border-box;
  opacity: 1;
  transform: scale(1);
}

.experienceTitle {
  animation: fadeInPage 0.8s ease-in-out;
  animation-delay: 0.2s;
  animation-fill-mode: both;
  font-family: "Raleway", sans-serif;
  font-size: 2.8rem;
  font-weight: 400;
  background: linear-gradient(90deg, #892cdc, #d4a1f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* Soft neon glow */
  text-shadow: 0 0 8px rgba(137, 44, 220, 0.6), 0 0 16px rgba(137, 44, 220, 0.2);
  text-align: center;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.experienceSubtitle {
  line-height: 1.6;
  font-size: 1.2rem;
  margin: 0 auto 3rem;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0px 2px 4px rgba(137, 44, 220, 0.2);
  text-align: center;
  animation: fadeInPage 0.8s ease-in-out;
  animation-delay: 0.4s;
  animation-fill-mode: both;
}

.timeline {
  position: relative;
  margin: 0 auto;
  padding: 2rem 0;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timelineLine {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 4px;
  height: 100%;
  background-color: #892cdc;
  box-shadow: 0 0 10px #892cdc, 0 0 20px #892cdc, 0 0 40px #892cdc;
  z-index: 0;
  animation: glowPulse 2s infinite ease-in-out;
}

.timelineItem {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 0;
  padding: 2rem;
  width: 100%;
}

.card {
  position: relative;
  background: linear-gradient(
    135deg,
    rgba(38, 38, 38, 0.95),
    rgba(20, 20, 20, 0.95)
  ); /* Matches navbar and footer */
  border-radius: 10px;
  padding: 1.5rem;
  width: 40%;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4), 0 0 20px rgba(137, 44, 220, 0.3);
  color: white;
  margin-top: 1rem;
  box-sizing: border-box;
  opacity: 0;
  transform: translateY(30px) scale(0.95);
  transition: all 0.5s ease-out;
}
.card.visible {
  animation: fadeInSlideUp 0.8s ease-in-out forwards;
}
.card.left {
  text-align: left;
  margin-right: auto;
}

.card.right {
  text-align: left;
  margin-left: auto;
}

.timelineMarker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
  width: 20px;
  background-color: #892cdc;
  border: 2px solid #ffffff;
  border-radius: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px #892cdc, 0 0 20px #892cdc, 0 0 30px #892cdc;
  animation: glowPulse 2s infinite ease-in-out;
}
.markerDate {
  position: absolute;
  font-size: 0.9rem;
  color: #ffffff;
  background: #1a1a1a;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  top: 100%;
  white-space: nowrap;
  transform: translateY(8px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.companyLogo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 1rem;
  border: 2px solid #892cdc;
}
.companyLogo img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.role {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

.company {
  font-size: 1.2rem;
  background: linear-gradient(90deg, #892cdc, #d4a1f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* Soft neon glow */
  text-shadow: 0 0 8px rgba(137, 44, 220, 0.6), 0 0 16px rgba(137, 44, 220, 0.2);
  margin-bottom: 0.5rem;
}

.location,
.duration {
  font-size: 1rem;
  color: #aaaaaa;
  margin-bottom: 1rem;
}

.descriptionList {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
}

.descriptionList li {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}
@keyframes glowPulse {
  0% {
    box-shadow: 0 0 10px #892cdc, 0 0 20px #892cdc, 0 0 40px #892cdc;
  }
  50% {
    box-shadow: 0 0 15px #d084ff, 0 0 30px #d084ff, 0 0 50px #d084ff;
  }
  100% {
    box-shadow: 0 0 10px #892cdc, 0 0 20px #892cdc, 0 0 40px #892cdc;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(30px) scale(0.95);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
@keyframes fadeInPage {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@media (max-width: 1246px) {
  .timeline {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .experienceContainer {
    padding: 3rem 1rem;
  }
  .experienceTitle {
    font-size: 2.2rem;
  }
  .experienceSubtitle {
    font-size: 0.9rem;
  }
  .timelineLine {
    width: 2px;
  }

  .timelineItem {
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;
  }

  .card {
    width: 80%;
    margin: 1rem auto;
    text-align: center;
  }

  .card.left,
  .card.right {
    margin: 0;
  }

  .timelineMarker {
    top: -20px;
    left: 50%;
    transform: translate(-50%, 0);
  }

  .markerDate {
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
  }

  .companyLogo {
    width: 60px;
    height: 60px;
  }

  .role {
    font-size: 1.2rem;
  }

  .company {
    font-size: 1rem;
  }

  .descriptionList li {
    font-size: 0.85rem;
  }
}
@media (max-width: 576px) {
  .experienceTitle {
    font-size: 2rem;
  }
}

@media (max-width: 500px) {
  .timeline {
    padding: 1rem 0;
  }

  .card {
    width: 95%;
    padding: 1rem;
  }

  .timelineLine {
    width: 1px;
  }

  .timelineMarker {
    height: 16px;
    width: 16px;
    box-shadow: 0 0 5px #892cdc, 0 0 10px #892cdc, 0 0 20px #892cdc;
  }

  .markerDate {
    font-size: 0.7rem;
  }

  .companyLogo {
    width: 50px;
    height: 50px;
  }

  .role {
    font-size: 1rem;
  }

  .company {
    font-size: 0.9rem;
  }

  .descriptionList li {
    font-size: 0.8rem;
  }
}

@media (max-width: 435px) {
  .experienceContainer {
    padding: 0.5rem;
    margin-top: 3rem;
  }
  .timeline {
    padding: 0.5rem 0;
  }

  .card {
    width: 90%;
    padding: 0.8rem;
  }

  .timelineLine {
    width: 1px;
  }

  .timelineMarker {
    height: 14px;
    width: 14px;
    box-shadow: 0 0 4px #892cdc, 0 0 8px #892cdc, 0 0 16px #892cdc;
  }

  .markerDate {
    font-size: 0.65rem;
    top: -30px;
  }

  .companyLogo {
    width: 40px;
    height: 40px;
  }

  .role {
    font-size: 0.9rem;
  }

  .company {
    font-size: 0.8rem;
  }

  .location,
  .duration {
    font-size: 0.7rem;
  }

  .descriptionList li {
    font-size: 0.75rem;
  }
}

@media (max-width: 387px) {
  .experienceTitle {
    font-size: 1.8rem;
  }

  .experienceSubtitle {
    font-size: 0.8rem;
  }
}
@media (max-width: 335px) {
  .experienceTitle {
    font-size: 1.6rem;
  }

  .experienceSubtitle {
    font-size: 0.8rem;
  }

  .timeline {
    padding: 0.3rem 0;
  }

  .card {
    width: 95%;
    padding: 0.6rem;
  }

  .timelineLine {
    width: 0.5px;
  }

  .timelineMarker {
    height: 12px;
    width: 12px;
    box-shadow: 0 0 3px #892cdc, 0 0 6px #892cdc, 0 0 12px #892cdc;
  }

  .markerDate {
    font-size: 0.6rem;
    top: -25px;
  }

  .companyLogo {
    width: 35px;
    height: 35px;
  }

  .role {
    font-size: 0.8rem;
  }

  .company {
    font-size: 0.75rem;
  }

  .location,
  .duration {
    font-size: 0.65rem;
  }

  .descriptionList li {
    font-size: 0.7rem;
    margin-bottom: 0.4rem;
  }
}

@media (max-width: 320px) {
  .experienceTitle {
    margin: 2rem;
    font-size: 1.5rem;
  }
}
