.PosingCanvas-wrapper {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  height: 750px;
  z-index: 3;
}
.touch-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 2;
  touch-action: pan-y;
  pointer-events: auto;
}
@media (max-width: 335px) {
  .PosingCanvas-wrapper {
    width: 300px;
  }
}
@media (max-width: 292px) {
  .PosingCanvas-wrapper {
    z-index: 3;
  }
}
@media (max-width: 216px) {
  .PosingCanvas-wrapper {
    width: 250px;
  }
}
