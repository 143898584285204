.squattingCanvas-wrapper {
  width: 100%;
  height: calc(100vh - 8.7vh);
  position: relative;
}
.touch-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 2;
  touch-action: pan-y;
  pointer-events: auto;
}
@media (max-width: 1024px) {
  .squattingCanvas-wrapper {
    height: 75vh;
  }
}

@media (max-width: 768px) {
  .squattingCanvas-wrapper {
    height: 60vh;
  }
}

@media (max-width: 480px) {
  .squattingCanvas-wrapper {
    height: 411.5px;
  }
}


