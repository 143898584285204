.blogDetail-container {
    max-width: 1200px;
    margin: 8rem auto;
    padding: 4rem 3rem;
    background: linear-gradient(135deg, #14111b 0%, #0f0d16 50%, #0a0912 100%);
    border-radius: 20px;
    color: #e0e0e0;
    box-shadow: 0 1px 40px rgba(0, 0, 0, 0.85), 0 0 30px rgba(137, 44, 220, 0.4);
    text-align: center;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    animation: fadeIn 0.8s ease forwards;
  }
  
  .blogDetail-backButton {
    background: linear-gradient(90deg, #6a0dad, #7a1ea1);
    color: #fff;
    border: none;
    padding: 1rem 2rem;
    border-radius: 10px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 3rem;
    box-shadow: 0 0 8px rgba(91, 14, 170, 0.6), 0 0 15px rgba(91, 14, 170, 0.4);
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
  }
  .blogDetail-backButton:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(122, 30, 161, 0.7);  
  }
  
  .blogDetail-title-wrapper {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 4rem;
  }
  
  .blogDetail-title-event {
    font-size: 3rem;
    background: linear-gradient(90deg, #ffffff, #f0f0f0);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.6);
    margin: 0;
    animation: fadeIn 0.8s ease forwards;
  }
  .blogDetail-title-project {
    font-size: 2.5rem;
    background: linear-gradient(90deg, #892cdc, #d4a1f2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 8px rgba(137, 44, 220, 0.6), 0 0 16px rgba(137, 44, 220, 0.2);
    margin: 0;
    margin-top: 1.5rem;
    animation: fadeIn 0.8s ease forwards;
  }
  .blogDetail-title-challenge {
    font-size: 2rem;
    background: linear-gradient(90deg, #f7f4ff, #eae2ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 5px rgba(230, 226, 255, 0.6);
    margin: 0;
    margin-top: 0.5rem;
    animation: fadeIn 0.8s ease forwards;
  }
  
  
  .blogDetail-section {
    margin: 4rem 0;
    padding: 3rem 2rem;
    border-radius: 20px;
    
    background: linear-gradient(135deg, #0d0d0d, #1a0e2c);
   
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    text-align: left;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .blogDetail-section:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.6);
  }
  
  
  .blogDetail-heading {
    font-size: 2rem;
    text-shadow: 0 0 8px rgba(137, 44, 220, 0.6), 0 0 16px rgba(137, 44, 220, 0.2);
    background: linear-gradient(90deg, #892cdc, #d4a1f2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid rgba(137, 44, 220, 0.3);
    padding-bottom: 0.5rem;
  }
  
  
  .blogDetail-text {
    font-size: 1.2rem;
    line-height: 1.8;
    color: #e0e0e0;
    margin-top: 1.5rem;
  }
  .blogDetail-text ul,
  .blogDetail-text li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  
  .image-container {
    border: 1px solid rgba(137, 44, 220, 0.3);
    border-radius: 15px;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .blogDetail-image {
    width: 100%;
    max-width: 950px;
    border-radius: 15px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  }
  .blogDetail-image:hover {
    transform: scale(1.02);
    box-shadow: 0 5px 14px rgba(137, 44, 220, 0.25);
  }
  
  
  .lightbox {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    animation: fadeInLightbox 0.5s ease;
  }
  .lightbox-image {
    max-width: 70%;
    max-height: 90vh;
    border-radius: 15px;
    transition: transform 0.3s ease;
  }
  .lightbox-image:hover {
    transform: scale(1.02);
  }
  
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .fadeIn {
    animation: fadeIn 0.8s ease forwards;
  }
  @keyframes fadeInLightbox {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  
  
  
  @media (max-width: 1024px) {
    .blogDetail-title-event {
      font-size: 2.75rem;
    }
    .blogDetail-title-project {
      font-size: 2.25rem;
    }
    .blogDetail-title-challenge {
      font-size: 2rem;
    }
    .blogDetail-heading {
      font-size: 1.9rem;
    }
    .blogDetail-text {
      font-size: 1.15rem;
    }
    .blogDetail-backButton {
      font-size: 1.05rem;
    }
  }
  
  
  @media (max-width: 768px) {
    .blogDetail-container {
      margin: 6rem 1rem;
      padding: 2rem 1.5rem;
    }
    .blogDetail-title-event {
      font-size: 2.5rem;
    }
    .blogDetail-title-project {
      font-size: 2.1rem;
    }
    .blogDetail-title-challenge {
      font-size: 1.8rem;
    }
    .blogDetail-heading {
      font-size: 1.7rem;
    }
    .blogDetail-text {
      font-size: 1rem;
    }
    .blogDetail-backButton {
      font-size: 1rem;
      padding: 0.9rem 1.5rem;
    }
    .blogDetail-section {
      padding: 2rem 1rem;
    }
  }
  
  
  @media (max-width: 570px) {
    .blogDetail-title-event {
      font-size: 2.3rem;
    }
    .blogDetail-title-project {
      font-size: 2rem;
    }
    .blogDetail-title-challenge {
      font-size: 1.7rem;
    }
    .blogDetail-heading {
      font-size: 1.5rem;
    }
    .blogDetail-text {
      font-size: 0.95rem;
    }
    .blogDetail-backButton {
      font-size: 0.9rem;
      padding: 0.8rem 1.5rem;
    }
    .blogDetail-section {
      margin: 3rem 0;
      padding: 1.5rem 1rem;
    }
  }
  @media (max-width: 523px){
    .blogDetail-text{
        overflow-wrap: break-word;
    }
  }
  
  @media (max-width: 480px) {
    .blogDetail-title-event {
      font-size: 2rem;
    }
    .blogDetail-title-project {
      font-size: 1.8rem;
    }
    .blogDetail-title-challenge {
      font-size: 1.6rem;
    }
    .blogDetail-heading {
      font-size: 1.4rem;
    }
    .blogDetail-text {
      font-size: 0.9rem;
    }
    .blogDetail-backButton {
      padding: 0.8rem 1.5rem;
      font-size: 0.9rem;
    }
    .blogDetail-section {
      margin: 2.5rem 0;
      padding: 1.5rem 1rem;
      border-radius: 8px;
    }
    .image-container{
        padding: 0.8rem;
    }
    .lightbox-image {
      max-width: 90%;
      max-height: 95vh;
      border-radius: 8px;
    }
    .blogDetail-image{
      border-radius: 8px;
    }
    .image-container{
      border-radius: 8px;
    }
  }
  
  
  @media (max-width: 370px) {
    .blogDetail-title-event {
      font-size: 1.8rem;
    }
    .blogDetail-title-project {
      font-size: 1.6rem;
    }
    .blogDetail-title-challenge {
      font-size: 1.4rem;
    }
    .blogDetail-heading {
      font-size: 1.2rem;
    }
    .blogDetail-text {
      font-size: 0.85rem;
    }
    .blogDetail-backButton {
      padding: 0.7rem 1.2rem;
      font-size: 0.85rem;
    }
    .blogDetail-section {
      margin: 2rem 0;
      padding: 1.2rem 0.8rem;
    }
  }
  
  
  @media (hover: none) {
    .blogDetail-image:hover {
      transform: none;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    }
  }

