.about-cont {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  padding: 2rem 1rem;
  padding-bottom: 5rem;
  gap: 2rem;
  box-sizing: border-box;
}
.aboutIntro {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
}
.meSquatting {
  flex: 1 1 auto;
  min-width: 200px;
  max-width: 400px;
}
.aboutText {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 650px;
}
.headText {
  font-family: "Raleway", sans-serif;
  font-size: 2.8rem;
  font-weight: 400;
  background: linear-gradient(90deg, #892cdc, #d4a1f2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* Soft neon glow */
  text-shadow: 0 0 8px rgba(137, 44, 220, 0.6), 0 0 16px rgba(137, 44, 220, 0.2);
  text-align: left;
  margin-bottom: 1rem;
  animation: gradientGlow 4s infinite alternate;
}

#firstText,
#secondText {
  font-size: 1.3rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
}
.activities {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.activities li {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #ffffff;
  animation: subtleFade 3s infinite alternate ease-in-out;
}

.activities > li > img {
  height: 30px;
  width: 30px;
  transition: transform 0.3s ease-in-out;
}

.activities img:hover {
  transform: scale(1.2);
}
.skillsSection {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: center;
}

.professional-skillSet {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: linear-gradient(
    135deg,
    rgba(38, 38, 38, 0.95),
    rgba(20, 20, 20, 0.95)
  );
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(137, 44, 220, 0.3),
    0 0 20px rgba(208, 132, 255, 0.2);
  width: 90%;
  max-width: 100%;
  gap: 1rem;
  transition: box-shadow 0.5s ease-in-out;
}
.professional-skillSet:hover {
  box-shadow: 0 0 15px rgba(137, 44, 220, 0.4),
    0 0 30px rgba(208, 132, 255, 0.3);
}
.sliderStyle {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  padding: 0;
}
.sliderStyle img {
  padding: 0.5rem 0rem;
  width: 75px;
  height: 75px;
  object-fit: contain;
  transition: filter 0.3s ease-in-out;
  filter: drop-shadow(0 0 5px rgba(137, 44, 220, 0.15));
}
.sliderStyle img:hover {
  transform: scale(1.05);
  filter: drop-shadow(0 0 8px rgba(137, 44, 220, 0.25));
}
/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes gradientGlow {
  0% {
    text-shadow: 0px 2px 4px rgba(137, 44, 220, 0.3),
      0px 4px 6px rgba(137, 44, 220, 0.2);
  }
  100% {
    text-shadow: 0px 4px 8px rgba(137, 44, 220, 0.5),
      0px 6px 10px rgba(137, 44, 220, 0.4);
  }
}

@keyframes subtleFade {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media (max-width: 1024px) {
  .headText {
    font-size: 2.5rem;
  }
  #firstText,
  #secondText {
    font-size: 1.3rem;
    text-align: justify;
  }
  .aboutText {
    max-width: 90%;
    align-items: center;
    text-align: center;
  }
  .activities li {
    font-size: 1.2rem;
  }
  .skillsSection {
    padding: 1rem;
    gap: 2rem;
  }
  .professional-skillSet {
    gap: 0.8rem;
  }
}

@media (max-width: 848px) {
  .aboutIntro {
    flex-direction: column;
    text-align: center;
  }
  .meSquatting {
    margin-bottom: 1rem;
    max-width: 300px;
  }
  .headText {
    font-size: 2.2rem;
  }
  #firstText,
  #secondText {
    font-size: 1.2rem;
  }
  .activities li {
    font-size: 1.1rem;
  }
  .professional-skillSet {
    padding: 1rem;
    gap: 0.5rem;
  }
  .sliderStyle {
    width: 70px;
    height: 70px;
  }

  .sliderStyle img {
    width: 65px;
    height: 65px;
  }
}

@media (max-width: 480px) {
  .activities img:hover,
  .sliderStyle img:hover {
    transform: none;
    filter: drop-shadow(0 0 5px rgba(137, 44, 220, 0.15));
  }

  .professional-skillSet:hover {
    box-shadow: 0 0 10px rgba(137, 44, 220, 0.3),
      0 0 20px rgba(208, 132, 255, 0.2); 
  }
  .about-cont {
    min-height: 100vh;
    padding: 1rem;
    gap: 1rem;
  }
  .headText {
    font-size: 1.8rem;
  }
  #firstText,
  #secondText {
    font-size: 1rem;
  }
  .activities li {
    font-size: 1rem;
  }
  .skillsSection {
    gap: 1.8rem;
  }
  .professional-skillSet {
    padding: 0.8rem;
    gap: 0.4rem;
  }
}
@media (max-width: 355px) {
  .meSquatting {
    max-width: 200px;
  }
}

@media (max-width: 320px) {
  .headText {
    font-size: 1.5rem;
  }
  #firstText,
  #secondText {
    font-size: 0.9rem;
  }
  .activities li {
    font-size: 0.9rem;
  }
  .skillsSection {
    padding: 0.5rem;
  }
  .professional-skillSet {
    padding: 0.5rem;
    gap: 0.3rem;
  }
}
