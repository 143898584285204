*::-webkit-scrollbar {
  display: none;
}
body,
html {
  margin: 0;
  padding: 0;
}
.home-cont {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 70px;
  min-height: calc(100vh - 8.7vh);
  font-size: 2.5rem;
  padding: 0 5%;
}
.text-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 50%;
  gap: 1.5rem;
  padding: 2rem 0rem;
}
.text-1 {
  margin-bottom: 0.5rem;
  width: 100%;
  font-size: 1.8rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}
.text-2 {
  width: 100%;
  font-size: 2.5rem;
  font-weight: 400;
  color: #ffffff;
  background: linear-gradient(90deg, #892cdc, #d4a1f2, #892cdc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.05rem;
  animation: gradientGlow 4s infinite alternate;
}
.text-2 > #name {
  font-size: 3rem;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0px 0px 10px rgba(137, 44, 220, 0.4),
    0px 0px 15px rgba(137, 44, 220, 0.6);
  animation: pulseGlow 2s infinite alternate;
}

.text-3 {
  color: rgba(255, 255, 255, 0.8);
  margin-top: 2rem;
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: 0.03rem;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  animation: fadeIn 1.5s ease-in-out;
}
.me {
  width: 50%;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes gradientGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes pulseGlow {
  0% {
    text-shadow: 0px 0px 6px rgba(137, 44, 220, 0.4),
      0px 0px 12px rgba(137, 44, 220, 0.6);
  }
  100% {
    text-shadow: 0px 0px 10px rgba(137, 44, 220, 0.5),
      0px 0px 15px rgba(137, 44, 220, 0.7);
  }
}

@keyframes subtleFade {
  0% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 1127px) {
  .home-cont {
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
  }
  .me {
    width: 70%;
    order: 1;
  }
  .text-cont {
    width: 100%;
    align-items: center;
    text-align: center;
  }
  .text-1 {
    font-size: 1.6rem;
  }
  .text-2 {
    font-size: 2.2rem;
  }
  .text-2 > #name {
    font-size: 2.6rem;
  }
  .text-3 {
    font-size: 1.4rem;
  }
}
@media (max-width: 975px) {
  .home-cont {
    font-size: 1.8rem;
  }

  .text-2 > #name {
    font-size: 2rem;
  }
  .text-1 {
    font-size: 1.4rem;
  }
  .text-2 {
    font-size: 2rem;
  }
  .text-2 > #name {
    font-size: 2.4rem;
  }
  .text-3 {
    font-size: 1.3rem;
  }

  .me {
    width: 90%;
    margin-bottom: 2rem;
  }
}
@media (max-width: 768px) {
  .home-cont {
    font-size: 1.6rem;
    padding: 1.5rem 1rem;
  }
  .text-1 {
    font-size: 1.3rem;
  }
  .text-2 {
    font-size: 1.8rem;
  }
  .text-2 > #name {
    font-size: 2rem;
  }
  .text-3 {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .home-cont {
    min-height: 100vh;
    font-size: 1.4rem;
    padding: 1rem 0.5rem;
  }
  .text-1 {
    font-size: 1.2rem;
  }
  .text-2 {
    font-size: 1.6rem;
  }
  .text-2 > #name {
    font-size: 1.8rem;
  }
  .text-3 {
    font-size: 1.1rem;
  }
}
@media (max-width: 375px) {
  .home-cont {
    font-size: 1.3rem;
    padding: 1rem 0;
  }
  .text-1 {
    font-size: 1.1rem;
  }
  .text-2 {
    font-size: 1.4rem;
  }
  .text-2 > #name {
    font-size: 1.6rem;
  }
  .text-3 {
    font-size: 1rem;
  }
}

@media (max-width: 355px) {
  .home-cont {
    padding: 0.8rem 0;
  }
  .text-1 {
    font-size: 1rem;
  }
  .text-2 {
    font-size: 1.3rem;
  }
  .text-2 > #name {
    font-size: 1.5rem;
  }
  .text-3 {
    font-size: 0.9rem;
  }
}

@media (max-width: 320px) {
  .home-cont {
    font-size: 1.2rem;
  }
  .text-1 {
    font-size: 0.9rem;
  }
  .text-2 {
    font-size: 1.2rem;
  }
  .text-2 > #name {
    font-size: 1.4rem;
  }
  .text-3 {
    font-size: 0.8rem;
  }
}
