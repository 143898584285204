.carouselContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 0;
    margin-top: 2rem;
    padding: 4rem;
    box-sizing: border-box;
  }
  
  .blogTitle {
    font-family: "Raleway", sans-serif;
    font-size: 2.8rem;
    font-weight: 400;
    background: linear-gradient(90deg, #892cdc, #d4a1f2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* Soft neon glow */
    text-shadow: 0 0 8px rgba(137, 44, 220, 0.6), 0 0 16px rgba(137, 44, 220, 0.2);
    text-align: center;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
  }
  
  .carousel {
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 1200px;
    overflow: hidden;
    position: relative;
  }
  
  .carouselCard {
    text-align: center;
    background: linear-gradient(
      135deg,
      rgba(38, 38, 38, 0.95),
      rgba(20, 20, 20, 0.95)
    );
    color: #fff;
    border-radius: 15px;
    padding: 1.5rem;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3), 0 0 20px rgba(137, 44, 220, 0.4);
    width: 400px;
    height: 580px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.5s ease, opacity 0.5s ease, background 0.5s ease;
    z-index: 1;
  }
  .fade-out {
    opacity: 0;
    transform: translateY(50px);
    z-index: 0;
  }
  
  .fade-in {
    opacity: 1;
    transform: translateY(0);
    z-index: 1;
  }
  .carouselCard:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4), 0 0 30px rgba(137, 44, 220, 0.5);
  }
  .monitorWrapper {
    width: 100%;
    height: 300px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(137, 44, 220, 0.2);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
  }
  
  .arrows {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 4rem);
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    padding: 0;
  }
  
  .arrow {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background: linear-gradient(90deg, #6a0dad, #7a1ea1);
    box-shadow: 0 0 8px rgba(91, 14, 170, 0.6), 0 0 15px rgba(91, 14, 170, 0.4);
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .arrow:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(137, 44, 220, 0.8);
    animation: subtleFloat 1s infinite ease-in-out;
  }
  .arrow svg {
    width: 24px;
    height: 24px;
    fill: white;
  }

  
  .blogDescription, .challengeName {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    line-height: 1.4;
    margin: 0.6rem;
    animation: fadeIn 0.8s ease-in;
  }
  .challengeName {
    font-size: 1.1rem;
    background: linear-gradient(90deg, #892cdc, #d4a1f2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* Soft neon glow */
    text-shadow: 0 0 8px rgba(137, 44, 220, 0.6), 0 0 16px rgba(137, 44, 220, 0.2);
  }

  .blogName {
    font-size: 1.6rem;
    font-weight: bold;
    color: #ffffff;
    margin: 0.5rem 0;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
  }
  .blogButton {
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .blogButton button {
    background: linear-gradient(90deg, #6a0dad, #7a1ea1);
    color: white;
    border: none;
    padding: 0.6rem 1.2rem;
    border-radius: 20px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 0 8px rgba(91, 14, 170, 0.6), 0 0 15px rgba(91, 14, 170, 0.4);
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.8);
  }
  
  .blogButton button:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(137, 44, 220, 0.4);
  }
  .blogSubtitle {
    font-family: "Raleway", sans-serif;
    font-size: 1.2rem;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    margin-bottom: 1.1rem;
    max-width: 600px;
    line-height: 1.6;
  }
  
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes scaleUp {
    0% {
      transform: scale(0.85);
      opacity: 0.8;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.98);
      opacity: 1;
    }
  }
  
  @keyframes subtleFloat {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-5px);
    }
  }
  
  @media (max-width: 768px) {
    .carousel {
      padding: 2rem;
    }
  
    .carouselCard {
      width: 300px;
      height: 510px;
    }
  
    .monitorWrapper {
      width: 100%;
      height: 460px;
    }
  
    .blogTitle {
      font-size: 2.2rem;
    }
  
    .blogSubtitle {
      font-size: 0.9rem;
    }
  
    .blogName {
      font-size: 1.4rem;
    }
  
    .blogDescription{
      font-size: 0.9rem;
    }
    .challengeName{
      font-size: 0.95rem; 
    }
    .blogButton button {
      font-size: 0.8rem;
      padding: 0.5rem 1rem;
    }
  
    .arrows {
      padding: 0 1rem;
    }
  
    .arrow {
      width: 45px;
      height: 45px;
    }
  
    .arrow svg {
      width: 20px;
      height: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .carouselContainer {
      padding: 1rem;
    }
    .carousel {
      width: 100%;
      padding: 3rem 1.5rem 4rem;
    }
  
    .carouselCard {
      width: 300px;
      height: 500px;
    }
  
    .blogTitle {
      margin: 2rem;
      font-size: 2rem;
    }
  
    .blogSubtitle {
      font-size: 0.9rem;
    }
  
    .blogName {
      font-size: 1.2rem;
    }
  
    .blogDescription {
      font-size: 0.85rem;
    }
    .challengeName{
      font-size: 0.9rem;
    }
    .blogButton button {
      font-size: 0.75rem;
      padding: 0.4rem 0.8rem;
    }
  
    .arrows {
      position: relative;
      top: 0;
      left: 0%;
      gap: 0;
      margin-bottom: 2rem;
      transform: translate(0%, 0%);
      width: 150px;
    }
  
    .arrow {
      width: 40px;
      height: 40px;
      box-shadow: 0 0 10px rgba(137, 44, 220, 0.8);
    }
  
    .arrow svg {
      width: 18px;
      height: 18px;
    }
    .carouselCard:hover {
      transform: none;
      box-shadow: none;
    }
  
    .carouselCard {
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3), 0 0 20px rgba(137, 44, 220, 0.2);
    }
    .arrow:hover {
      transform: none;
      box-shadow: 0 0 10px rgba(137, 44, 220, 0.8);
    }
    .blogButton button:hover {
      transform: none;
      box-shadow: none;
    }
  }
  
  @media (max-width: 387px) {
    .carouselContainer {
      padding: 0.4rem;
    }
    .carousel {
      width: 100%;
      padding: 3rem 1.4rem 3rem;
    }
  
    .carouselCard {
      padding: 1.2rem;
      width: 100%;
      height: 440px;
    }
  
    .blogTitle {
      margin: 2rem;
      font-size: 1.8rem;
      width: 100%;
    }
  
    .blogSubtitle {
      font-size: 0.8rem;
      width: 100%;
    }
  
    .blogName {
      width: 100%;
      font-size: 1rem;
    }

    .blogDescription{
      width: 100%;
      font-size: 0.75rem;
    }
    .challengeName{
      width: 100%;
      font-size: 0.8rem; 
    }
    .blogButton button {
      font-size: 0.65rem;
    }
  
    .arrow {
      width: 30px;
      height: 30px;
    }
  
    .arrow svg {
      width: 15px;
      height: 15px;
    }
  }
  
  @media (max-width: 320px) {
    .blogTitle {
      font-size: 1.5rem;
    }
  }
  