.splash {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #1a1a1a, #292929);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  overflow: hidden;
}

.splash-overlay {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.splash-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  font-family: "Raleway", sans-serif;
  font-size: 2.2rem;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0px 2px 6px rgba(137, 44, 220, 0.5),
    0px 4px 8px rgba(137, 44, 220, 0.3);
}

.splash-logo-symbol {
  font-size: 2.8rem;
  color: #892cdc;
  animation: splash-glowPulse 2s infinite alternate;
}

.splash-loading-bar {
  position: relative;
  width: 280px;
  height: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.splash-progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: linear-gradient(90deg, #892cdc, #d4a1f2);
  animation: splash-progressBar 2s infinite;
  border-radius: 50px;
}

@keyframes splash-glowPulse {
  0% {
    text-shadow: 0px 0px 6px rgba(137, 44, 220, 0.4),
      0px 0px 10px rgba(137, 44, 220, 0.6);
  }
  100% {
    text-shadow: 0px 0px 10px rgba(137, 44, 220, 0.6),
      0px 0px 15px rgba(137, 44, 220, 0.8);
  }
}

@keyframes splash-progressBar {
  0% {
    width: 0;
  }
  50% {
    width: 70%;
  }
  100% {
    width: 100%;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .splash-logo-wrapper {
    font-size: 1.8rem;
    gap: 0.3rem;
  }

  .splash-logo-symbol {
    font-size: 2.3rem;
  }

  .splash-loading-bar {
    width: 240px;
    height: 8px;
  }
}

@media (max-width: 600px) {
  .splash-logo-wrapper {
    font-size: 1.3rem;
  }

  .splash-logo-symbol {
    font-size: 2rem;
  }

  .splash-loading-bar {
    width: 220px;
    height: 7px;
  }
}

@media (max-width: 471px) {
  .splash-logo-wrapper {
    font-size: 1rem;
  }

  .splash-logo-symbol {
    font-size: 1.5rem;
  }

  .splash-loading-bar {
    width: 190px;
    height: 6px;
  }
}

@media (max-width: 379px) {
  .splash-logo-wrapper {
    font-size: 0.85rem;
  }

  .splash-logo-symbol {
    font-size: 1.1rem;
  }

  .splash-loading-bar {
    width: 150px;
    height: 4px;
  }
}

@media (max-width: 310px) {
  .splash-logo-wrapper {
    font-size: 0.65rem;
  }

  .splash-logo-symbol {
    font-size: 0.9rem;
  }

  .splash-loading-bar {
    width: 120px;
    height: 3px;
  }
}
